<template>
  <!-- 号码池页面 -->
  <div class="number">
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>号码列表</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询
            </el-button>
          </div>
          <div class="ml-10">
            <el-dropdown>
              <el-button class="button-el" size="medium">
                <icon-font type="icon-tianjia1" class="button-icon-font"/>
                添加号码
                <icon-font type="icon-copy-4-36" class="button-icon-font"/>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="fileToImport(1)">导入并分配</el-dropdown-item>
                  <el-dropdown-item @click="fileToImport(2)">文件导入</el-dropdown-item>

                  <!-- <el-dropdown-item @click="editAmount()">批量添加</el-dropdown-item> -->
                  <el-dropdown-item @click="addData()">批量添加</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="ml-10">
            <el-dropdown>
              <el-button class="button-el" size="medium">
                <icon-font type="icon-bangdingguanxi" class="button-icon-font"/>
                批量操作
                <icon-font type="icon-copy-4-36" class="button-icon-font"/>
              </el-button>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="distributionVisible=true">批量分配</el-dropdown-item>
                  <el-dropdown-item @click="packBranch()">勾选分配</el-dropdown-item>

                  <el-dropdown-item @click="recycling(1)">批量回收</el-dropdown-item>
                  <el-dropdown-item @click="allEidtDialog = true">批量编辑</el-dropdown-item>
                  <el-dropdown-item @click="recycling(2)">批量退网</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="ml-10">
            <el-dropdown>
              <el-button class="button-el" size="medium">
                <icon-font type="icon-daochushuju" class="button-icon-font"/>
                导出数据
                <icon-font type="icon-copy-4-36" class="button-icon-font"/>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- <el-dropdown-item @click="setSearchExport(0)">全部</el-dropdown-item> -->
                  <!-- <el-dropdown-item @click="setSearchExport(1)">选定</el-dropdown-item> -->
                  <el-dropdown-item @click="setSearchExport(2)">搜索结果</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
          <!-- new -->
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col :span="24">
        <div>
          <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    max-height="700" size="mini" :row-style="{height:'50px'}" :cell-style="{padding:'0px'}"
                    :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
                    @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <!-- <el-table-column label="ID" width="170px">
              <template #default="scope">{{
                scope.row.id == null ? "-" : scope.row.id
              }}</template>
            </el-table-column> -->
            <el-table-column label="号码" fixed width="130px">
              <template #default="scope">{{
                  scope.row.number == null ? "-" : scope.row.number
                }}
              </template>
            </el-table-column>
            <el-table-column label="省份" width="130">
              <template #default="scope">{{
                  scope.row.province == null ? "-" : scope.row.province
                }}
              </template>
            </el-table-column>
            <el-table-column label="城市" width="130">
              <template #default="scope">{{
                  scope.row.city == null ? "-" : scope.row.city
                }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="区号" width="130">
              <template #default="scope">{{
                scope.row.areaCode == null ? "-" : scope.row.areaCode
              }}</template>
            </el-table-column> -->
            <el-table-column label="服务商" width="120px">
              <template #default="scope">{{
                  scope.row.amountName == null ? "-" : scope.row.amountName
                }}
              </template>
            </el-table-column>
            <el-table-column label="接口类型" width="130">
              <template #default="scope">{{
                  scope.row.interfaceName == null ? "-" : scope.row.interfaceName
                }}
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template #default="scope">{{ scope.row.createTime == null ? '-' : scope.row.createTime }}</template>
            </el-table-column>
            <el-table-column label="拨打次数">
              <template #default="scope">{{ scope.row.bindNums == null ? '-' : scope.row.bindNums }}</template>
            </el-table-column>
            <!-- <el-table-column
                  label="上次更新时间">
                  <template #default="scope">{{ scope.row.updateTime == null?'-': scope.row.updateTime}}</template>
                  </el-table-column> -->
            <el-table-column label="号码状态" width="110px">
              <template #default="scope">
                <span :class="scope.row.status == 0?'yes-green':'no-red'">{{ scope.row.statusText }}</span>
              </template>
            </el-table-column>
            <el-table-column label="计费类型/是否录音" width="150px">
              <template #default="scope">
                <div v-if="scope.row.type === 1">
                  <el-tag size="mini">单计费</el-tag>
                  <span
                      :class="scope.row.record === 1 ? 'yes-green-span':'no-red-span'">{{
                      scope.row.record && scope.row.record === 1 ? "有录音" : "无录音"
                    }}</span>
                </div>
                <div v-else-if="scope.row.type === 2">
                  <el-tag size="mini" type="success">双计费</el-tag>
                  <span
                      :class="scope.row.record === 1 ? 'yes-green-span':'no-red-span'">{{
                      scope.row.record && scope.row.record === 1 ? "有录音" : "无录音"
                    }}</span>
                </div>

              </template>
            </el-table-column>
            <el-table-column label="号码行业">
              <template #default="scope">{{
                  scope.row.industryName == null
                      ? "-"
                      : scope.row.industryName
                }}
              </template>
            </el-table-column>
            <el-table-column label="分配次数">
              <template #default="scope">{{
                  scope.row.distributionCount
                }}
              </template>
            </el-table-column>
            <el-table-column width="150" label="分配用户" :show-overflow-tooltip="true">
              <template #default="scope">{{
                  scope.row.nickName == null ? "-" : scope.row.nickName
                }}
              </template>
            </el-table-column>
            <el-table-column width="150" label="公司名称">
              <template #default="scope">{{
                  scope.row.company == null ? "-" : scope.row.company
                }}
              </template>
            </el-table-column>
            <!-- <el-table-column width="100" label="备注" :show-overflow-tooltip="true">
              <template #default="scope">{{
                scope.row.remark == null ? "-" : scope.row.remark
              }}</template>
            </el-table-column> -->
            <el-table-column label="操作" fixed="right" width="180">
              <template #default="scope">
                <!-- <el-tooltip content="号码分配" placement="top" effect="light">
                  <el-button
                    class="editor"
                    size="mini"
                    icon="el-icon-coordinate"
                    @click="branchFun(scope.row)"
                    round
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="号码回收" placement="top" effect="light">
                  <el-button
                    class="editor"
                    size="mini"
                    @click="recycling(scope.row)"
                    round
                  >
                    <icon-font type="icon-ai49" />
                  </el-button>
                </el-tooltip> -->
                <!-- <el-tooltip content="号码绑定" placement="top" effect="light">
                  <el-button
                    class="editor"
                    size="mini"
                    @click="bindFun(scope.row)"
                    round
                  >
                    绑定
                  </el-button>
                </el-tooltip> -->
                <!-- <el-tooltip content="号码编辑" placement="top" effect="light">
                  <el-button
                    class="editor"
                    size="mini"
                    @click="modify(scope.row)"
                    round
                  >
                    编辑
                  </el-button>
                </el-tooltip> -->
                <!-- <el-tooltip content="号码删除" placement="top" effect="light">
                  <el-button
                    class="deletetable"
                    size="mini"
                    round
                    @click="confirmEvent(scope.row.id)"
                  >
                    删除
                  </el-button>
                </el-tooltip> -->
                <div class="flex-nowrap-space-around">
                  <span class="button-font-color" @click="bindFun(scope.row)">
                    <icon-font type="icon-bangding"/>
                    绑定
                  </span>
                  <span class="button-font-color" @click="modify(scope.row)">
                    <icon-font type="icon-bianji"/>
                    编辑
                  </span>
                  <span class="button-font-color" @click="confirmEvent(scope.row.id)">
                    <icon-font type="icon-shanchu"/>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="currentPage" :page-sizes="[20, 30, 40, 50, 100,500]"
                       :page-size="searchForm.pageSize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>


    <!-- 弹窗→操作号码 -->
    <div class="popup-view">
      <el-form label-position="left" label-width="100px" :model="upform" ref="upform1" :rules="rules">
        <el-dialog :title="operationType == 1?'添加数据':'修改数据'" v-model="centerDialogVisible"
                   :close-on-click-modal="false"
                   :destroy-on-close="true" width="30%">
          <div class="center-body">
            <!-- <el-row :gutter="12">
              <el-col :span="8"> -->
            <div class="form-out-box p-0-30">
              <el-form-item label="号码：" prop="number">
                <el-input v-if="operationType == 1" v-model="upform.number" :autosize="{ minRows: 3, maxRows: 4 }"
                          type="textarea" placeholder="请输入号码(输入为多个号码时请换行输入)"></el-input>
                <el-input v-else v-model.trim="upform.number" placeholder="请输入号码"></el-input>
              </el-form-item>
              <!-- <el-form-item label="错误号码：" class="redItem" v-if="showError == true">
                      <div class="redItem">{{ errorNumber }}</div>
                    </el-form-item> -->
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="号码设置：">
                <el-input v-model="upform.options" type="textarea" placeholder="请填写号码设置"
                          :autosize="{ minRows: 2, maxRows: 6 }"/>
              </el-form-item>
              <!-- <el-form-item label="地区" prop="areaCode">
                <el-cascader :options="options" :props="{value: 'name',label: 'name'}" v-model="upformArea" @change="upformAreaChange"></el-cascader>
              </el-form-item> -->
              <!-- <el-form-item label="省份：">
                <el-input v-model="upform.province" placeholder="请输入省份"></el-input>
              </el-form-item> -->
              <el-form-item label="省份：" prop="value3">
                <el-cascader filterable clearable placeholder="选择省份城市" style="width: 100%" v-model="upform.value3"
                             :options="option"></el-cascader>
              </el-form-item>
              <!-- </el-col>
                  <el-col :span="8"> -->
              <!-- <el-form-item label="城市：">
                <el-input v-model="upform.city" placeholder="请输入城市"></el-input>
              </el-form-item> -->
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="区号：" prop="areaCode">
                <el-input v-model="upform.areaCode" placeholder="请输入区号"></el-input>
              </el-form-item>
              <!-- </el-col> -->
              <!-- <el-col :span="8">-->
              <el-form-item label="服务商：" prop="amountId">
                <el-select v-model="upform.amountId" placeholder="请选择" filterable style="width: 100%" clearable
                           @change="changeValues">
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </el-col>  -->
              <!-- <el-col :span="8"> -->
              <el-form-item label="接口类型：" prop="interfaceId">
                <el-select v-model="upform.interfaceId" placeholder="请选择" filterable style="width: 100%" clearable>
                  <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="号码状态：" prop="status">
                <el-select v-model="upform.status" placeholder="请选择" style="width: 100%" clearable>
                  <el-option v-for="(item, index) in ['正常', '禁用']" :key="index" :label="item" :value="index">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="计费类型：" prop="type">
                <el-select v-model="upform.type" placeholder="请选择" style="width: 100%" clearable>
                  <el-option v-for="(item, index) in ['单计费', '双计费']" :key="index" :label="item"
                             :value="index + 1">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="号码行业：" prop="industryId">
                <el-cascader :props="industryTreeProps" filterable placeholder="选择/搜索号码行业" clearable
                             style="width: 100%"
                             v-model="industryValue"
                             :options="filterIndustryList"></el-cascader>
              </el-form-item>
              <el-form-item label="禁入行业：" prop="unindustryIds">
                <el-cascader :props="unIndustryTreeProps" filterable placeholder="选择/搜索禁入行业" clearable
                             style="width: 100%"
                             v-model="unIndustryValue"
                             :options="filterIndustryList"></el-cascader>
              </el-form-item>


              <!-- <el-select v-model="upform.businessType" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="(item, index) in industryList" :key="index" :label="item.value" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="是否录音：" prop="record">
                <el-select v-model="upform.record" placeholder="请选择" style="width: 100%" clearable>
                  <el-option v-for="(item, index) in ['否', '是']" :key="index" :label="item" :value="index">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button class="cancel" plain @click="centerDialogVisible = false" round>取消</el-button>
              <el-button class="confirmAdd" @click="addSubmit(operationType,'upform1')" round>
                保存
              </el-button>
            </el-form-item>
            <!-- </el-col>
            </el-row> -->
          </div>
        </el-dialog>
      </el-form>
    </div>



    <div class="popup-view">
      <el-form label-position="left" label-width="100px" :model="upform1" ref="upform2" :rules="rules">
        <el-dialog title="分配小号" v-model="distributionVisible1"
                   :close-on-click-modal="false"
                   :destroy-on-close="true" width="30%">
          <div class="center-body">
            <!-- <el-row :gutter="12">
              <el-col :span="8"> -->
            <div class="form-out-box p-0-30">
              <el-form-item label="号码：" prop="number1">
                <el-input v-model="upform1.number1" :autosize="{ minRows: 3, maxRows: 4 }"
                          type="textarea" placeholder="请输入号码(输入为多个号码时请换行输入)"></el-input>
              
              </el-form-item>
      
              <el-form-item label="用户：" prop="uid">
                  <el-select  v-model="upform1.uid" filterable placeholder="请选择"
                             style="width: 100%">
                    <el-option v-for="(item, index) in user_list" :key="index"
                               :label="`${item.nickname||''}--${item.company||''}--(Id:${item.uid})`" :value="item.uid">
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1?'no-red':''">
                          <span>{{ item.nickname }}--</span>
                          <span>{{ item.company }}--</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>


              <el-form-item label="备注：">
                <el-input v-model="upform.remark" type="textarea" placeholder="请填写备注"
                          :autosize="{ minRows: 2, maxRows: 6 }"/>
              </el-form-item>
       
        
              
            </div>
            <el-form-item>
              <el-button class="cancel" plain @click="distributionVisible = false" round>取消</el-button>
              <el-button class="confirmAdd" @click="addSubmit1('upform2')" round>
                保存
              </el-button>
            </el-form-item>
            <!-- </el-col>
            </el-row> -->
          </div>
        </el-dialog>
      </el-form>
    </div>

    <!-- 弹窗→导入文件 -->
    <div class="popup-view">
      <el-form label-position="top" label-width="100px" :model="upform" ref="upform">
        <el-dialog title="号码导入" v-model="centerUploadVisible" width="50%" :close-on-click-modal="false"
                   :destroy-on-close="true">
          <div class="center-body">
            <el-row :gutter="12">
              <!-- <el-col :span="8">
                <el-form-item label="通信账户：">
                  <el-select
                    v-model="importArray.amountId"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in amount_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="是否录音：">
                  <el-select v-model="importArray.record" placeholder="请选择" style="width: 100%" clearable>
                    <el-option v-for="(item, index) in ['否', '是']" :key="index" :label="item" :value="index">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!--                <el-form-item label="号码行业：">-->
                <!--                  <el-select v-model="importArray.businessType" placeholder="请选择" style="width: 100%" clearable>-->
                <!--                    <el-option v-for="(item, index) in industryList" :key="index" :label="item.value"-->
                <!--                               :value="item.id">-->
                <!--                    </el-option>-->
                <!--                  </el-select>-->
                <!--                </el-form-item>-->
                <el-form-item label="号码行业：">
                  <el-cascader :props="industryTreeProps" filterable placeholder="请选择" clearable
                               style="width: 100%"
                               v-model="importArray.businessType"
                               :options="industryTreeList"></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="接口类型：">
                  <el-select v-model="importArray.interfaceId" placeholder="请选择" style="width: 100%" clearable>
                    <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>


            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="centerUploadVisible = false" round>取消</el-button>
              <el-button class="confirmAdd" @click="importFileFun()" round>
                提交
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→号码绑定 -->
    <div class="popup-view">
      <el-form label-position="left" label-width="90px" :model="bind_form" :rules="rules" ref="Bind_form" size="mini">
        <el-dialog title="号码绑定" v-model="centerBindVisible" width="390px" :close-on-click-modal="false"
                   :destroy-on-close="true">
          <div class="center-body p-0-20">
            <!-- <el-row :gutter="12">
              <el-col :span="8"> -->
            <el-form-item label="绑定号码：">
              <el-input v-model="bind_form.telX" disabled></el-input>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="主叫号码：" prop="telA">
              <el-input v-model="bind_form.telA" placeholder="请输入主叫号码"></el-input>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="被叫号码：" prop="telB">
              <el-input v-model="bind_form.telB" placeholder="请输入被叫号码"></el-input>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="绑定时间：" prop="expire">
              <el-input-number v-model="bind_form.expire" @change="handleChange" :min="3" :max="30"
                               label="设置绑定时间">
              </el-input-number>
            </el-form-item>
            <!-- </el-col>
            </el-row> -->
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="cancelBindNumber('Bind_form')" round>取消</el-button>
              <el-button class="confirmAdd" @click="bindNumber('Bind_form')" round>绑定</el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→回收小号 -->
    <div class="pop-view">
      <el-form label-position="top" label-width="100px" :model="bind_form" ref="bind_form">
        <el-dialog :title="recyclingType==1?'回收小号':'退网小号'" :before-close="bfClose"
                   v-model="centerBranchVisibleOther"
                   :close-on-click-modal="false" :destroy-on-close="true" width="30%">
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="recyclingType==1?8:24" :push="recyclingType==1?2:0">
                <!-- <el-form-item
                  :label="'小号：' + multipleSelection.length + '个'"
                >
                  <div class="showIdsList">
                    <div v-if="multipleSelection.length == 0">请勾选小号</div>
                    <div v-for="item in multipleSelection" :key="item.id">
                      {{ item.number }}
                    </div>
                  </div>
                </el-form-item> -->
                <el-input type="textarea" placeholder="请输入小号，每行一个小号" v-model="inputMobiles"
                          :rows="12"></el-input>
                <div v-if="recyclingType==2" style="font-size:14px;color:#aaa;margin-top:5px;margin-left:5px;">
                  需要退网的号码必须完成回收操作，正在使用的号码无法强行退网
                </div>
                <div style="font-size:14px;color:#aaa;margin-top:5px;margin-left:5px;" class="noticetext"
                     v-html="recycleNumbers"></div>
                <div style="font-size:14px;color:#aaa;margin-top:5px;margin-left:5px;" class="noticetext"
                     v-html="existNumbers"></div>
              </el-col>
              <el-col :span="1" v-if="recyclingType==1" :push="2">
                <el-button size="small" plain @click="search">搜索<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </el-col>
              <el-col :span="10" v-if="recyclingType==1" :push="5">
                <!-- <el-form-item label="用户：">
                  <el-select v-model="uids" filterable multiple placeholder="请选择用户">
                    <el-option
                      v-for="(item, index) in user_list"
                      :key="index"
                      :label="item.nickname"
                      :value="item.uid"
                    >
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1?'no-red':''">
                          <span>{{ item.nickname }}</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                </el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group class="checkbox" v-model="checkedPerson" @change="handleCheckedCitiesChange">
                  <el-checkbox v-for="item in persons" :label="item.uid" :key="item.uid">
                    {{ item.nickname }}--{{ item.company }}--(ID:{{ item.uid }})
                  </el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="bfClose(false)" round>取消</el-button>
              <el-button class="confirmAdd" @click="backNum()" round>{{ recyclingType == 1 ? '回收' : '退网' }}
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→分配小号 -->

    <div class="pop-view">
      <el-form label-position="top" label-width="100px" :model="bind_form" ref="bind_form" :rules="rules">
        <el-dialog title="分配小号" v-model="centerBranchVisible" :close-on-click-modal="false" :destroy-on-close="true"
                   @close="cancelBranchNumber()" width="30%">
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="10">
                <el-form-item :label="'小号：' + multipleSelection.length + '个'">
                  <div class="showIdsList">
                    <div v-if="multipleSelection.length == 0">请勾选小号</div>
                    <div v-for="item in multipleSelection" :key="item.id">
                      {{ item.number }}
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="用户：" prop="uid">
                  <el-select @change="ReloadBranchNumberError" v-model="branch_form.uid" filterable placeholder="请选择"
                             style="width: 100%">
                    <el-option v-for="(item, index) in user_list" :key="index"
                               :label="`${item.nickname||''}--${item.company||''}--(Id:${item.uid})`" :value="item.uid">
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1?'no-red':''">
                          <span>{{ item.nickname }}--</span>
                          <span>{{ item.company }}--</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <div>
                  <template v-if="errObj.errList && errObj.errList.length!== 0 && errObj.errCode === 203   ">
                    <div>以下线路未设置价格：</div>
                    <div class="showErrList">
                      <div v-for="(item,i) in errObj.errList " :key="i">
                        <span class="no-red">{{ item.interfaceName }}</span>
                        <span class="ml-10 no-red">{{ typeText(item.type) }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-if=" errObj.errCode === 204 ">

                    <div class="showErrList">
                      <div>分配失败！失败原因：<span class="no-red"><br/> {{ errObj.errText }}</span></div>
                    </div>
                  </template>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item :label="'备注'">
                  <el-input v-model="branch_form.remark" :rows="2" type="textarea"/>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="cancelBranchNumber()" round>取消</el-button>
              <el-button class="confirmAdd"
                         @click="branchNumber()" round>分配
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>


    <div class="pop-view">
      <el-form label-position="top" label-width="100px" :model="upform1" ref="bind_form" :rules="rules">
        <el-dialog title="分配小号" v-model="distributionVisible" :close-on-click-modal="false" :destroy-on-close="true"
                   @close="cancelBranchNumber()" width="30%">
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="10">
               
                  <el-form-item label="小号：" prop="number1">
                <el-input v-model="upform1.number1" :autosize="{ minRows: 3, maxRows: 10 }"
                          type="textarea" placeholder="请输入号码(输入为多个号码时请换行输入)"></el-input>
              
              </el-form-item>
               
              </el-col>
              <el-col :span="10">
                <el-form-item label="用户：" prop="uid">
                  <el-select @change="ReloadBranchNumberError" v-model="upform1.uid" filterable placeholder="请选择"
                             style="width: 100%">
                    <el-option v-for="(item, index) in user_list" :key="index"
                               :label="`${item.nickname||''}--${item.company||''}--(Id:${item.uid})`" :value="item.uid">
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1?'no-red':''">
                          <span>{{ item.nickname }}--</span>
                          <span>{{ item.company }}--</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <div>
                  <template v-if="errObj.errList && errObj.errList.length!== 0 && errObj.errCode === 203   ">
                    <div>以下线路未设置价格：</div>
                    <div class="showErrList">
                      <div v-for="(item,i) in errObj.errList " :key="i">
                        <span class="no-red">{{ item.interfaceName }}</span>
                        <span class="ml-10 no-red">{{ typeText(item.type) }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-if=" errObj.errCode === 204 ">

                    <div class="showErrList">
                      <div>分配失败！失败原因：<span class="no-red"><br/> {{ errObj.errText }}</span></div>
                    </div>
                  </template>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item :label="'备注'">
                  <el-input v-model="upform1.remark" :rows="2" type="textarea"/>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="cancelBranchNumber()" round>取消</el-button>
              <el-button class="confirmAdd"
                         @click="branchNumber1()" round>分配
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→操作号码 -->

    <div class="popup-view">
      <el-form label-position="top" label-width="100px" :model="allEideFrom" ref="upformChange" :rules="rules">
        <el-dialog title="批量编辑" v-model="allEidtDialog" :close-on-click-modal="false" :destroy-on-close="true"
                   width="50%">
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="12">
                <el-form-item :label="'小号：' + multipleSelection.length + '个'">
                  <div class="showIdsList">
                    <div v-if="multipleSelection.length == 0">请勾选小号</div>
                    <div v-for="item in multipleSelection" :key="item.id">
                      {{ item.number }}
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="号码状态：" prop="status">
                  <el-select v-model="allEideFrom.status" placeholder="请选择" style="width: 100%" clearable>
                    <el-option v-for="(item, index) in ['正常', '禁用']" :key="index" :label="item" :value="index">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="计费类型：" prop="type">
                  <el-select v-model="allEideFrom.type" placeholder="请选择" style="width: 100%" clearable>
                    <el-option v-for="(item, index) in ['单计费', '双计费']" :key="index" :label="item"
                               :value="index + 1">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!--                <el-form-item label="号码行业：" prop="industryId">-->
                <!--                  <el-select filterable v-model="allEideFrom.industryId" placeholder="请选择" style="width: 100%"-->
                <!--                             clearable>-->
                <!--                    <el-option v-for="(item, index) in industryList" :key="index" :label="item.name" :value="item.id">-->

                <!--                    </el-option>-->
                <!--                  </el-select>-->
                <!--                </el-form-item>-->
                <el-form-item label="号码行业：" prop="industryId">
                  <el-cascader :props="industryTreeProps" filterable placeholder="请选择" clearable
                               style="width: 100%"
                               v-model="allEideFrom.industryId"
                               :options="industryTreeList"></el-cascader>
                </el-form-item>
                <el-form-item label="是否录音：" prop="record">
                  <el-select v-model="allEideFrom.record" placeholder="请选择" style="width: 100%" clearable>
                    <el-option v-for="(item, index) in ['否', '是']" :key="index" :label="item" :value="index">
                    </el-option>
                  </el-select>
                </el-form-item>
              
                <el-form-item label="服务商">
                <el-select v-model="allEideFrom.amountId" placeholder="请选择查询的服务商" style="width: 100%" clearable
                           filterable @change="changeValues">
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="接口类型">
                <el-select v-model="allEideFrom.interfaceId" placeholder="请选择查询的接口类型" style="width: 100%"
                           clearable
                           filterable>
                  <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="allEidtDialog = false" round>取消</el-button>
              <el-button class="confirmAdd" @click="allEidtSubmit('upformChange')" round>
                保存
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 批量添加 -->
    <div>
      <el-dialog :title="importType == 1?'导入并分配':'文件导入'" v-model="showBulkImport" width="30%"
                 :close-on-click-modal="false" :destroy-on-close="true">
        <bulkImport @confirmOK="confirmImports" @quxiaos="quxiaosData" :importType="importType"
                    :industry-list="industryList" :industry-tree-list="industryTreeList"></bulkImport>
      </el-dialog>
    </div>
    <!-- 筛选查询弹窗 -->
    <div>
      <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true"
                 width="30%">
        <div class="">
          <el-form label-position="left" label-width="100px" size="small" :model="searchForm" ref="upform">
            <div class="form-out-box p-0-30">
              <el-form-item label="号码">
                <el-input v-model.trim="searchForm.number" placeholder="请输入查询的号码" clearable></el-input>
              </el-form-item>
              <el-form-item label="地区">
                <el-cascader :options="options" :props="{value: 'name',label: 'name',checkStrictly: true}"
                             v-model="searchArea" @change="searchAreaChange"></el-cascader>
              </el-form-item>
              <!-- <el-form-item label="省份">
                <el-input v-model.trim="searchForm.province" placeholder="请输入查询的省份" clearable></el-input>
              </el-form-item>
              <el-form-item label="城市">
                <el-input v-model.trim="searchForm.city" placeholder="请输入查询的城市" clearable></el-input>
              </el-form-item>
              <el-form-item label="区号">
                <el-input v-model.trim="searchForm.areaCode" placeholder="请输入查询的区号" clearable></el-input>
              </el-form-item> -->
              <el-form-item label="分配次数">
                <el-input v-model.trim="searchForm.distributionCount" placeholder="请输入查询的次数"
                          clearable></el-input>
              </el-form-item>
              <el-form-item label="服务商">
                <el-select v-model="searchForm.amountId" placeholder="请选择查询的服务商" style="width: 100%" clearable
                           filterable @change="changeValues">
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="接口类型">
                <el-select v-model="searchForm.interfaceId" placeholder="请选择查询的接口类型" style="width: 100%"
                           clearable
                           filterable>
                  <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!--              <el-form-item label="限制行业">-->
              <!--                <el-select filterable v-model="searchForm.industryId" placeholder="请选择查询的限制行业"-->
              <!--                           style="width: 100%" clearable>-->
              <!--                  <el-option v-for="(item, index) in industryList" :key="index" :label="item.name"-->
              <!--                             :value="item.id">-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
              <el-form-item label="限制行业">
                <el-cascader :props="industryTreeProps" filterable placeholder="请选择查询的限制行业" clearable
                             style="width: 100%"
                             v-model="searchForm.industryId"
                             :options="filterSearchIndustryList"></el-cascader>
              </el-form-item>
              <el-form-item label="分配用户">
                <el-select v-model="searchForm.uid" placeholder="请选择" style="width: 100%" clearable filterable>
                  <el-option v-for="(item, index) in user_list" :key="index"
                             :label="`${item.nickname||''}--${item.company||''}--(Id:${item.uid})`" :value="item.uid">
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1?'no-red':''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="号码状态">
                <el-radio v-model="searchForm.status" label="">全部</el-radio>
                <el-radio v-model="searchForm.status" label="0">正常</el-radio>
                <el-radio v-model="searchForm.status" label="1">禁用</el-radio>
              </el-form-item>
              <el-form-item label="计费类型">
                <el-radio v-model="searchForm.type" label="">全部</el-radio>
                <el-radio v-model="searchForm.type" label="1">单计费</el-radio>
                <el-radio v-model="searchForm.type" label="2">双计费</el-radio>
              </el-form-item>
              <el-form-item label="是否录音">
                <el-radio v-model="searchForm.record" label="">全部</el-radio>
                <el-radio v-model="searchForm.record" label="0">关闭</el-radio>
                <el-radio v-model="searchForm.record" label="1">开启</el-radio>
              </el-form-item>
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="searchTapFun" round>
                  查询
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import amount from '@/api/open/amount/amount'

import number from '@/api/open/privacy/number'
import interfacets from '@/api/open/privacy/interface'
import amountts from '@/api/open/privacy/amount'
import callts from '@/api/open/privacy/call'
import userts from '@/api/web/user'
import usernumber from '@/api/open/privacy/userNumber'
import {message} from 'ant-design-vue'
import ImportFile from '@/components/importFile.vue'
import bulkImport from '@/components/bulkImport1.vue'
import {IconFont} from '@/utils/iconfont'
import dictionary from '@/api/system/dictionary'
import area from '@/api/open/area/area'
import { toRaw, watch } from 'vue';
import deepClone from "@/utils/deepClone";

export default {
  name: 'Number',
  components: {
    ImportFile,
    bulkImport,
    IconFont,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      let num = 0,
          types = null,
          errorItem
      if (value == null) {
        this.showError = false
        callback(new Error('请填写号码 (多个号码请换行输入)'))
      } else if (value.length == 0) {
        this.showError = false
        callback(new Error('请填写号码 (多个号码请换行输入)'))
      } else {
        let newArr = []
        newArr = value.split(/[(\r\n)\r\n]+/)
        newArr.forEach((item, i) => {
          if (
              item.match(
                  /^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-?\d{7,8}$|^9\d{9}$|^2\d{9}$|^8\d{9}$/
              ) == null
              // item.match(
              //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/
              // ) == null
          ) {
            // if(item.match(/(010\d{8})|(0[2-9]\d{9})|(13\d{9})|(14[57]\d{8})|(15[0-35-9]\d{8})|(18[0-35-9]\d{8})/) == null){
            // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
            num++
            types = i
            errorItem = item
          }
        })
        if (num != 0) {
          this.showError = true
          this.errorNumber = errorItem
          callback(
              new Error(
                  '请输入正确的号码 (请检查第' +
                  (types + 1) +
                  '行：' +
                  errorItem +
                  ')'
              )
          )
        } else {
          this.showError = false
          callback()
        }
      }
    }
    return {
      distributionVisible:false,
      label: [],
      option: [],
      branchNumberLoading: false,
      searchArea: [],
      upformArea: [],
      options: [],
      existNumbers: '', //未查到的号码
      recycleNumbers: '', //需要回收的号码
      isIndeterminate: false,
      checkAll: false,
      checkedPerson: [],
      persons: [],
      inputMobiles: '',
      uids: [],
      number: null,
      errorNumber: null, //显示错误号码
      showError: false, //显示错误号码
      tableData: [], //数据列表
      centerDialogVisible: false, // 弹窗--操作号码
      centerBranchVisibleOther: false, // 弹窗--回收小号
      centerUploadVisible: false, // 弹窗--文件上传
      centerBindVisible: false, // 弹窗--号码绑定
      centerBranchVisible: false, // 弹窗--分配小号
      allEidtDialog: false, // 弹窗--批量修改
      showBulkImport: false, //弹窗--批量添加
      theQueryDialog: false, //弹窗--查询
      activeNames: [], //折叠面板
      uploadData: {}, // 导入文件携带数据
      tableHeight: '', //表格高度
      operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, //分页
      total: 0, //总数据条数
      interface_id: [], // 拿到的接口类型
      amount_id: [], // 拿到的通信账户类型
      industryList: [],
      industryTreeList: [],
      industryValue: [],
      unIndustryValue: [],
      industryTreeProps: {
        multiple: false,
        checkStrictly: true,//Select any level of options
      },
      unIndustryTreeProps: {
        multiple: true,
        checkStrictly: true,//Select any level of options
      },
      user_list: [], // 用户的集合
      multipleSelection: [], // 选中小号的集合
      upform: {
        //添加号码
        number: '', // 号码
        province: '', // 省份
        city: '', // 城市
        areaCode: '', // 区号
        interfaceId: '', // 接口类型
        amountId: '', // 通信账户ID
        status: '', // 号码状态(0:正常/1:禁用)
        type: '', // 小号类型(1:单计费/2:双计费)
        businessType: '', // 号码行业(0:非金融/1:房产/2:家装)
        record: '', // 是否录音
        distributionCount: '', // 分配次数
        checkAll: false,
        checkedCities: ['上海', '北京'],
        cities: ['上海', '北京', '广州', '深圳'],
        value3: [],
        isIndeterminate: true,
      },
      upform1: {
        //添加号码
        number1: '', // 号码
        remark:'',
        uid:''
      },
      importArray: {
        // 导入文件的存储对象
        amountId: '',
        businessType: '',
        fileUrl: '',
        interfaceId: '',
        record: '',
      },
      searchForm: {
        page: 1,
        pageSize: 20,
        //搜索项
        number: '',
        province: '',
        city: '',
        areaCode: '',
        interfaceId: '',
        amountId: '',
        status: '',
        type: '',
        record: '',
        businessType: '',
        distributionCount: '',
      },
      allIds: [],
      bind_form: {
        // 绑定号码--表单
        telA: '',
        telB: '',
        telX: '',
        expire: 30,
      },
      branch_form: {
        // 分配号码--表单
        uid: '', // 用户id
        ids: [], // 小号数组
        remark: '',
      },
      allEideFrom: {
        ids: [],
        status: '',
        type: '',
        industryId: '',
        record: '',
        amountId:'',
         interfaceId:''
      },
      errObj: {
        errCode: null,
        errList: [],
        errVal: {
          nickName: '',
          company: '',
        },
      },
      loading: true, // loding 状态启用
      importType: 1,
      // 时间选择器快捷款项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            },
          },
        ],
      },
      rules: {
        number1: [{required: true,message: '请填写号码', }],

        number: [{required: true, trigger: 'blur', validator: checkNumber}],
        telA: [
          {
            required: true,
            message: '请填写主叫号码',
            trigger: 'blur',
          },
        ],
        telB: [
          {
            required: true,
            message: '请填写被叫号码',
            trigger: 'blur',
          },
        ],
        expire: [
          {
            required: true,
            message: '请选择绑定时间',
            trigger: 'blur',
          },
        ],
        // number: [
        //   {
        //     required: true,
        //     message: '请填写手机号码',
        //     trigger: 'blur',
        //   },
        //   {
        //     min: 11,
        //     max: 11,
        //     message: '手机号码长度不正确',
        //     trigger: 'blur',
        //   },
        // ],
        areaCode: [
          {
            required: true,
            message: '请输入区号',
            trigger: 'blur',
          },
        ],
        amountId: [
          {
            required: true,
            message: '请选择服务商',
            trigger: 'blur',
          },
        ],
        interfaceId: [
          {
            required: true,
            message: '请选择接口类型',
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择号码状态',
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: '请选择计费类型',
            trigger: 'blur',
          },
        ],
        // businessType: [
        //   {
        //     required: true,
        //     message: '请选择号码行业',
        //     trigger: 'blur',
        //   },
        // ],
        industryId: [
          {
            required: true,
            message: '请选择号码准入行业',
            trigger: 'blur',
          },
        ],
        // unindustryIds: [
        //   {
        //     required: true,
        //     message: '请选择号码禁用行业',
        //     trigger: 'blur',
        //   },
        // ],
        value3: [
          {
            required: true,
            message: '请选择省份城市',
            trigger: 'blur',
          },
        ],
        record: [
          {
            required: true,
            message: '请选择是否录音',
            trigger: 'blur',
          },
        ],
        uid: [
          {
            required: true,
            message: '请选择分配的用户',
            trigger: 'blur',
          },
        ],
      },
      recyclingType: 1,
    }
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
  },
  mounted() {
    // amount.industryidlist().then((res) => {
    //   this.label = res.data
    // })

    dictionary.industry().then((res) => {
      //     console.log(res.data, '99999999999999999999999999999999999========================================')
      let arr = []
      this.industryTreeList = this.getIndustryTree(res.data, arr)
      ///  this.modeList = res.data
      this.industryList = this.getIndustryList(res.data)
    })
    this.city()
    this.getNumberList()
    this.getApiType()
    this.getAmountType()
    this.getUserList()
    this.getAreaList()
  },
  computed: {
    filterIndustryList() {
      //选择服务商后，只展示当下服务商行业、系统默认（amountId==0）和全行业（amountId==0）
      let res = [];
      res = this.industryTreeList?.filter(industry => {
        return industry.amountId == 0 || industry.amountId == this.upform.amountId;
      })
      return res || [];
    },
    filterSearchIndustryList() {
      //选择服务商后，只展示当下服务商行业、系统默认（amountId==0）和全行业（amountId==0）
      let res = [];
      res = this.industryTreeList?.filter(industry => {
        return industry.amountId == 0 || industry.amountId == this.searchForm.amountId;
      })
      return res || [];
    },
  },
  methods: {
    getBaseTree(data, returnData) {

      if (!data) return
      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          if (v.children) {
            returnData?.push({
              label: v.name,
              value: v.name,
              children: [],
            })
          } else {
            returnData?.push({
              label: v.name,
              value: v.name,
            })
          }

          if (v.children) {
            this.getBaseTree(v.children, returnData?.[i]?.children)
          }
        })
      }
// return returnData;
      this.option = returnData

// console.log(returnData, 'returnDatareturnDatareturnData')
    },
    city() {

      dictionary.cityList().then((res) => {
        console.log('000')
        let arr = []
        this.getBaseTree(res.data, arr)
        ///  this.modeList = res.data
      })
    },

    ReloadBranchNumberError() {
      this.errObj = {
        errCode: null,
        errList: [],
        errVal: {
          nickName: '',
          company: '',
        },
        errText: ''
      }
    },
    editAmount(e) {
      this.$router.push({
        path: '/open/editli',
        query: {id: ''},
      })
    },
    getAreaList() {
      area.dropDownTreeList().then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        } else {
          this.options = res.data
        }
      })
    },
    upformAreaChange(event) {
      this.upform.province = event[0]
      this.upform.city = event[1] || ''
      let item = this.options.find((e) => e.name == event[0])
      this.upform.areaCode = item.areaCode
      if (event[1]) {
        this.upform.areaCode = item.children.find(
            (e) => e.name == event[1]
        ).areaCode
      }
    },
    searchAreaChange(event) {
        this.searchForm.province = event[0].replace(/省|市$/, '')
       this.searchForm.city = event[1] ? event[1].replace(/市$/, '') : '';
      // this.searchForm.province = event[0].split('')
      // this.searchForm.province.splice(-1, 1)
      // this.searchForm.province = this.searchForm.province.join('')
      // if (event[1]) {
      //   this.searchForm.city = event[1].split('')
      //   this.searchForm.city.splice(-1, 1)
      //   this.searchForm.city = this.searchForm.city.join('')
      // } else {
      //   this.searchForm.city = ''
      // }
      
      // let item = this.options.find(e=>e.name==event[0])
      // this.searchForm.areaCode = item.code;
      // if(event[1]){
      //   this.searchForm.areaCode = item.children.find(e=>e.name==event[1]).code;
      // }
    },
    //回收小号模态框关闭前事件
    bfClose(done) {
      this.inputMobiles = ''
      this.checkedPerson = []
      this.isIndeterminate = false
      this.persons = []
      this.checkAll = false
      this.centerBranchVisibleOther = false
      this.existNumbers = ''
      this.recycleNumbers = ''
      done && done()
    },
    //根据号码搜索用户
    search() {
      let arr = this.inputMobiles.split(/\r\n|\r|\n/)
      arr = [...new Set(arr)]
      this.inputMobiles = arr.join('\n')
      if (!this.inputMobiles) {
        return this.$notify.warning('请输入小号')
      }
      number.numberUser(arr).then((res) => {
        if (res.code !== 200) {
          this.persons = []
          this.$message.error(res.message)
        } else {
          this.persons = res.data
        }
      })
    },
    handleCheckAllChange(val) {
      this.checkedPerson = val ? this.persons.map((e) => e.uid) : []
      // console.log(val)
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.persons.length
      this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.persons.length
    },
    // 选中服务商
    changeValues(val) {
      this.interface_id = []
      this.searchForm.interfaceId = ''
      this.upform.interfaceId = ''
      interfacets
          .getInterface({
            amountId: val,
          })
          .then((res) => {
            //this.interface_id = []
            this.interface_id = res.data
           //console.log( this.interface_id,'uuuuuuuuuuuuuuuuuuuuuu');
          })
    },
    //导入or分配
    fileToImport(type) {
      this.importType = type
      this.showBulkImport = true
    },
    // 上传成功
    confirmImports() {
      this.getNumberList()
      this.showBulkImport = false
    },
    quxiaosData() {
      this.showBulkImport = false
    },
    backNum() {
      let arr = this.inputMobiles.split(/\r\n|\r|\n/)
      arr = [...new Set(arr)]
      this.inputMobiles = arr.join('\n')
      if (!this.inputMobiles) {
        return this.$notify.warning('请输入小号')
      }
      if (this.recyclingType == 1) {
        if (this.checkedPerson.length == 0) {
          return this.$notify.error('请选择用户')
        }
        number
            .recycle({
              numbers: arr,
              uids: this.checkedPerson.map((e) => Math.floor(e)),
            })
            .then((res) => {
              if (res.code == 200) {
                this.getNumberList()
                this.$notify({
                  title: '成功',
                  message: '小号回收完成！',
                  type: 'success',
                })
                if (res.data.length == 0) {
                  this.bfClose(false)
                } else {
                  this.existNumbers = `<span style="color:#f00">${res.data.join(
                      ','
                  )}</span>未查到对应号码`
                  this.search()
                }
              } else {
                this.$message.error(res.message)
              }
            })
      } else {
        number.withdrawNetwork(arr).then((res) => {
          if (res.code == 200) {
            if (
                res.data.existNumbers.length == 0 &&
                res.data.recycleNumbers.length == 0
            ) {
              this.getNumberList()
              this.$notify.success('小号退网成功')
              this.bfClose(false)
            } else {
              if (res.data.recycleNumbers.length) {
                this.recycleNumbers = `请将<span style="color:#f00">${res.data.recycleNumbers.join(
                    ','
                )}</span>号码回收后再进行退网`
              }
              if (res.data.existNumbers.length) {
                this.existNumbers = `<span style="color:#f00">${res.data.existNumbers.join(
                    ','
                )}</span>未查到对应号码`
              }
            }
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },

    // 回收小号
    recycling(val) {
      this.recyclingType = val
      // this.branch_form.ids = [];
      // this.branch_form.ids = this.allIds;
      this.centerBranchVisibleOther = true
    },
    //下载模板
    downTemplate() {
      window.location.href =
          'https://hiszy.oss-cn-beijing.aliyuncs.com/upload/prd/templates/号码导入.xlsx?versionId=CAEQHxiBgMC8wffw4BciIGIwODhmNTc3NDNiMjQ5Mzg4Y2RlYWMwMjlhYzFiMzgx'
    },
    // 打包分配--点击
    packBranch() {
      if (this.multipleSelection.length < 1) {
        this.$notify({
          title: '失败',
          message: '请先勾选小号再选择批量分配',
          type: 'waring',
        })
      } else {
        this.branch_form.ids = this.allIds
        this.centerBranchVisible = true
      }
    },
    allEidtSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.allEideFrom.ids = this.allIds
          let params = deepClone(toRaw(this.allEideFrom));
          params.industryId = params.industryId ? Array.isArray(params.industryId) ? params.industryId[params.industryId.length - 1] : params.industryId : null;//单选返回是一维数组
          number
              .numberBatchEdit({
                ...params,
              })
              .then((res) => {
                this.getNumberList()
                this.$notify({
                  title: '成功',
                  message: '批量编辑完成！',
                  type: 'success',
                })
                this.allEidtDialog = false
              })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 列表多选--操作
    handleSelectionChange(val) {
      let newIds = []
      val.forEach((item) => {
        newIds.push(item.id)
      })
      this.multipleSelection = val
      this.allIds = newIds
    },
    cancelBranchNumber() {
      this.distributionVisible=false

      this.centerBranchVisible = false
      this.branch_form.uid = null
      this.ReloadBranchNumberError()
      this.upform1.number1 = ''
      this.upform1.remark = ''
      this.upform1.uid = ''
      this.distributionVisible=false
    },
    // 号码分配--确认
    branchNumber() {
      this.branchNumberLoading = true
      let userData = this.user_list.find(
          (item) => item.uid === this.branch_form.uid
      )
      usernumber
          .UserBranchNum({
            ...this.branch_form,
          })
          .then((res) => {
            if (res.code === 200) {
              message.success('分配成功')
              this.centerBranchVisible = false
              this.getNumberList()
            } else if (res.code === 203) {
              this.errObj = {
                errCode: res.code,
                errList: JSON.parse(res.message),
                errVal: {
                  nickName: '',
                  company: '',
                },
                errText: ''
              }
            } else if (res.code === 204) {
              console.log(JSON.parse(res.message)[0].error)
              this.errObj = {
                errCode: res.code,
                errList: [],
                errVal: {
                  nickName: '',
                  company: '',
                },
                errText: JSON.parse(res.message)[0].error
              }
            } else {
              message.error(res.message)
            }
          })
          .finally(() => {
            this.branchNumberLoading = false
          })
    },


    branchNumber1() {
      this.branchNumberLoading = true
      let userData = this.user_list.find(
          (item) => item.uid === this.branch_form.uid
      )



      let upArr = []
         let   arrLest = this.upform1.number1.split(/[(\r\n)\r\n]+/)
      
            number
                .distribution({
                  uid:this.upform1.uid,
                  numbers:arrLest,
                  remark:this.upform1.remark
                })
          .then((res) => {
            
            if (res.code === 200) {
              message.success('分配成功')
              this.distributionVisible = false
              this.getNumberList()
            } else if (res.code === 203) {
              this.errObj = {
                errCode: res.code,
                errList: JSON.parse(res.message),
                errVal: {
                  nickName: '',
                  company: '',
                },
                errText: ''
              }
            } else if (res.code === 204) {
              console.log(JSON.parse(res.message)[0].error)
              this.errObj = {
                errCode: res.code,
                errList: [],
                errVal: {
                  nickName: '',
                  company: '',
                },
                errText: JSON.parse(res.message)[0].error
              }
            } else {
              message.error(res.message)
            }
          })
          .finally(() => {
            this.branchNumberLoading = false
          })
    },
    typeText(type) {
      let typeMap = {
        0: '月租',
        1: '单计费',
        2: '双计费',
      }
      return typeMap[type]
    },
    // 号码分配--点击
    branchFun(val) {
      this.branch_form.ids = []
      this.branch_form.ids.push(val.id)
      this.centerBranchVisible = true
    },
    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
      })
    },
    // 号码绑定--确认
    bindNumber(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          callts
              .interfaceBind({
                ...this.bind_form,
              })
              .then((res) => {
                if (res.code !== 200) {
                  return this.$message.error(res.message)
                } else {
                  message.success('号码绑定成功')
                  this.centerBindVisible = false
                }
              })
            .catch((err) => {
             // this.centerBindVisible = false

            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cancelBindNumber(formName) {
      this.$refs[formName].resetFields()
      this.centerBindVisible = false
    },
    // 号码绑定--弹窗
    bindFun(val) {
      this.centerBindVisible = true
      this.bind_form.telX = val.number
    },
    // 重置搜索项
    searchTapReset(kind) {
      this.searchForm = {
        page: this.currentPage,
        pageSize: 20,
        status: '',
        type: '',
        record: '',
      }
      this.searchArea = []
      if (kind == 1) {
        this.getNumberList()
        this.theQueryDialog = false
      }
    },
    // 搜索特殊项
    searchTapFun() {
      this.getNumberList()
      this.theQueryDialog = false
    },
    // 获取文件上传后的返回路径--用于文件导入
    fileStringUrl(url) {
      this.importArray.fileUrl = url
      this.centerUploadVisible = true
    },
    // 文件导入
    importFileFun() {
      this.centerUploadVisible = false
      let params = deepClone(toRaw(this.importArray));
      params.businessType = params.businessType ? Array.isArray(params.businessType) ? params.businessType[params.businessType.length - 1] : params.businessType : null;//单选返回是一维数组
      number
          .importNumber({
            ...params,
          })
          .then((res) => {
            if (res.code === 200) {
              if (res.data === '导入成功,请勿重复导入!') {
                message.success(res.data)
              } else {
                message.success('数据已成功导入')
              }
            } else {
              message.error(res.message)
            }
            this.getNumberList()
          })
    },
    // 文件导出
    setSearchExport(type) {
      if (type == 0) {
        message.warning('正在导出...，请在消息模块下载')
        let params = deepClone(toRaw(this.searchForm));
        params.industryId = params.industryId ? Array.isArray(params.industryId) ? params.industryId[params.industryId.length - 1] : params.industryId : null;//单选返回是一维数组

        number
            .exportNumber({
              ...params,
            })
            .then((res) => {
              if (res.code != 200) {
                message.error(res.message)
              }
            })
      }
      if (type == 1) {
        if (this.multipleSelection.length == 0) {
          message.warning('请勾选需要导出的条目！')
        } else {
          message.warning('正在导出...，请在消息模块下载')
          number
              .exportNumber({
                ids: this.allIds,
              })
              .then((res) => {
                if (res.code != 200) {
                  message.error(res.message)
                }
              })
        }
      }
      if (type == 2) {
        message.warning('正在导出...，请在消息模块下载')
        let params = deepClone(toRaw(this.searchForm));
        params.industryId = params.industryId ? Array.isArray(params.industryId) ? params.industryId[params.industryId.length - 1] : params.industryId : null;//单选返回是一维数组
        number
            .exportNumber({
              ...params,
            })
            .then((res) => {
              if (res.code != 200) {
                message.error(res.message)
              }
            })
      }
    },
    // 号码删除
    confirmEvent(id) {
      this.$confirm('将删除该号码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            number
                .deleteNumber({
                  id,
                })
                .then((res) => {
                  this.$notify({
                    title: '成功',
                    message: '号码删除成功！',
                    type: 'success',
                  })
                  // 获取最新数据
                  this.handleSizeChange(this.searchForm.pageSize)
                })
          })
          .catch(() => {
            this.$notify.info({
              title: '通知',
              message: '已取消删除!',
            })
          })
    },
    // 号码修改--点击
    modify(val) {
      // this.$router.push({
      //   path: '/open/editli',
      //   query: { id: JSON.stringify(val) },
      // })

      // return

      this.upform = JSON.parse(JSON.stringify(val))
      // let province =  this.options.find(e=>e.name.includes(val.province));
      // this.upformArea[0] = province.name
      // if(val.city){
      //   this.upformArea[1] = province.children.find(e=>e.name.includes(val.city))?.name||'';
      // }
      // this.upform.businessType = this.upform.businessType.toString()

      // console.log('modify this.upform', this.upform)
      this.upform.value3 = this.upform.province + ',' + this.upform.city
      this.upform.value3 = this.upform.value3.split(',');
      //禁入行业展示 //多选结构是多维数组
      this.unIndustryValue = []; // unIndustryValue:[{0: 25(pid), 1: 220(id)},{0: 25, 1: 220}]
      let unindustryIdList = val.unindustryIds?.split(',') || [];

      unindustryIdList.forEach(id => {
        let find = this.industryList?.find(industry => industry.id == id);
        if (find) {
          // console.log('modify find', find);
          if (find.pid) {
            //现在是三级行业列表，所以还要向上查一级
            let parent = this.industryList?.find(industry => industry.id == find.pid);
            if (parent?.pid) {
              this.unIndustryValue.push([parent.pid, find.pid, find.id]);
            } else {
              this.unIndustryValue.push([find.pid, find.id]);
            }
          } else {
            this.unIndustryValue.push([find.id]);
          }
        }
      })
      // console.log('modify unindustryIdList,this.unIndustryValue', unindustryIdList, this.unIndustryValue);
      //准入行业展示 //单选结构是一维数组
      this.industryValue = []; // industryValue:[0: 25(pid), 1: 220(id)]
      let industryIdList = val.industryId ? [val.industryId] : [];
      industryIdList.forEach(id => {
        let find = this.industryList?.find(industry => industry.id == id);
        if (find) {
          if (find.pid) {
            //现在是三级行业列表，所以还要向上查一级
            let parent = this.industryList?.find(industry => industry.id == find.pid);
            if (parent?.pid) {
              this.industryValue.push(parent.pid);
            }
            this.industryValue.push(find.pid);
          }
          this.industryValue.push(find.id);
        }
      })
      // console.log('modify industryValue unIndustryValue', this.industryValue, this.unIndustryValue, unindustryIdList)

      // this.upform.industryId = Number.parseInt(this.upform.industryId);
      // this.upform.industryId = 2;
      // this.upform.unindustryIds = [28,29,30];
      this.upformArea = []
      this.operationType = 2
      if (this.upform.record === null) {
        this.upform.record = 0
      }
      this.getApiType()
      this.centerDialogVisible = true
    },
    unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a) && res.set(a, 1))
    },


    addSubmit1(formName) {
      // unIndustryValue:[{0: 25(pid), 1: 220(id)},{0: 25, 1: 220}]

      // this.upform.industryId = this.industryValue ? this.industryValue[this.industryValue.length - 1] : null;//单选返回是一维数组
      // this.upform.unindustryIds = this.unIndustryValue?.map(item => item[item.length - 1])?.join(',');//多选返回是多维数组
      // console.log('addSubmit', this.upform, this.industryValue, this.unIndustryValue, this.upform.industryId, this.upform.unindustryIds)//industryId
      // this.$refs[formName].validate();
      // return;

      this.$refs[formName].validate((valid) => {
        if (valid) {
         
            let arrLest = []
            let upArr = []
            arrLest = this.upform1.number1.split(/[(\r\n)\r\n]+/)
      
            number
                .distribution({
                  uid:this.upform1.uid,
                  numbers:arrLest,
                  remark:this.upform1.remark
                })
                .then((res) => {
                  this.centerDialogVisible = false
                  if (res.code === 200) {
                    message.success('分配成功')
                    // 获取最新数据
                    this.handleSizeChange(this.searchForm.pageSize)
                    this.$refs[formName].resetFields()
                  } else {
                    message.error(res.message)
                  }
                })
          
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },



    // 号码添加/修改--提交
    addSubmit(type, formName) {
      // unIndustryValue:[{0: 25(pid), 1: 220(id)},{0: 25, 1: 220}]

      // this.upform.industryId = this.industryValue ? this.industryValue[this.industryValue.length - 1] : null;//单选返回是一维数组
      // this.upform.unindustryIds = this.unIndustryValue?.map(item => item[item.length - 1])?.join(',');//多选返回是多维数组
      // console.log('addSubmit', this.upform, this.industryValue, this.unIndustryValue, this.upform.industryId, this.upform.unindustryIds)//industryId
      // this.$refs[formName].validate();
      // return;

      this.upform.industryId = this.industryValue ? this.industryValue[this.industryValue.length - 1] : null;//单选返回是一维数组
      this.upform.unindustryIds = this.unIndustryValue?.map(item => item[item.length - 1])?.join(',');//多选返回是多维数组
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (type === 1) {
            let arrLest = []
            let upArr = []
            arrLest = this.upform.number.split(/[(\r\n)\r\n]+/)
            this.unique(arrLest).forEach((item, i) => {
              upArr.push({
                number: item.trim(),
                areaCode: this.upform.areaCode,
                industryId: this.upform.industryId,
                unindustryIds: this.upform.unindustryIds,
                // city: this.upform.city,
                // province: this.upform.province,
                record: this.upform.record,
                status: this.upform.status,
                type: this.upform.type,
                amountId: this.upform.amountId,
                interfaceId: this.upform.interfaceId,
                city: this.upform.value3[1],
                province: this.upform.value3[0]
              })
            })
            // this.upform.number = this.unique(otherArr)
            number
                .addNumber({
                  numberListDtoList: upArr,
                })
                .then((res) => {
                  this.centerDialogVisible = false
                  if (res.code === 200) {
                    message.success('添加成功')
                    // 获取最新数据
                    this.handleSizeChange(this.searchForm.pageSize)
                    this.upform = {}
                  } else {
                    message.error(res.message)
                  }
                })
          } else {
            if (this.upform.value3) {
              this.upform.province = this.upform.value3[0]

              this.upform.city = this.upform.value3[1]

            }
            number
                .editNumber({
                  ...this.upform,
                })
                .then((res) => {
                  this.centerDialogVisible = false
                  if (res.code === 200) {
                    this.upformArea = []
                    message.success('修改成功')
                    // 获取最新数据
                    this.handleSizeChange(this.searchForm.pageSize)
                  } else {
                    message.error(res.message)
                  }
                })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 号码添加--填写
    addData() {
      this.upform = {}
      this.upformArea = []
      this.centerDialogVisible = true
      this.operationType = 1
      this.popupTitle = 0
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
    },

    // 获取通信账户类型
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })
      // amount.industryidlist({}).then((res) => {
      //   this.industryList = res.data
      // })
    },
    // 获取电话池列表
    getNumberList() {
      this.loading = true
      let params = deepClone(toRaw(this.searchForm));
      params.industryId = params.industryId ? Array.isArray(params.industryId) ? params.industryId[params.industryId.length - 1] : params.industryId : null;//单选返回是一维数组

      number
          .getList({
            ...params,
          })
          .then((res) => {
            this.tableData = res.data.records
            this.loading = false
            this.total = res.data.total
          })
    },
    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val
      this.getNumberList()
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.currentPage = val
      this.getNumberList()
    },
    getIndustryList(e) {
      let res = [];
      e.forEach(first => {
        res.push({
          amountId: first.amountId,
          id: first.id,
          name: first.name,
          pid: first.pid,
        })
        if (first.children && first.children.length > 0) {
          first.children?.forEach(second => {
            res.push({
              amountId: second.amountId,
              id: second.id,
              name: second.name,
              pid: second.pid,
            })
            if (second.children && second.children.length > 0) {
              second.children?.forEach(third => {
                res.push({
                  amountId: third.amountId,
                  id: third.id,
                  name: third.name,
                  pid: third.pid,
                })
              })
            }
          })
        }
      })
      return JSON.parse(JSON.stringify(res));
    },
    getIndustryTree(data, returnData) {
      if (!data) return returnData;
      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          if (v?.children && v.children.length > 0) {
            returnData?.push({
              amountId: v.amountId,//线路
              label: v.name,
              value: v.id,
              children: [],
            })
          } else {
            returnData?.push({
              amountId: v.amountId,//线路
              label: v.name,
              value: v.id,
            })
          }

          if (v.children) {
            this.getIndustryTree(v.children, returnData?.[i]?.children)
          }
        })
      }
      return returnData
      // console.log(returnData, 'returnDatareturnDatareturnData')
    },
    getIndustryFromTree(tree, prop, id) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i][prop] == id) {
          return tree[i];
        }
        if (tree[i].children && tree[i].children.length > 0) {
          let find = this.getIndustryFromTree(tree[i].children, prop, id);
          if (find) {
            return find;
          }
        }
      }
      return null;
    },
  },
}
</script>
<style lang="scss" scoped>
.number {
  padding-right: 10px;
}

.showIdsList {
  min-height: 30px;
  max-height: 260px;
  overflow-y: overlay;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-left: 16px;
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.form-out-box {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: overlay;
}

.yes-green-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #ecf5ff;
}

.no-red-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  background: #f4f4f5;
  border: 1px solid #f4f4f5;
}

.redItem {
  color: #f0142f;
}

.showErrList {
  height: 200px;
  overflow: scroll;
  width: 250px;
}

::v-deep(.checkbox .el-checkbox) {
  display: block;
}

.noticetext span {
  color: #f0142f;
}
</style>
